import React, { useState, useEffect } from "react"
import { IconPhoneOutgoing, IconMessage2, IconMapPin } from "@tabler/icons-react"
import { useParams } from "react-router-dom"
import "../../../../assets/css/App.css"
import Header from "../../../../components/Header"
import MapContainer from "../../../../components/MapContainer"
import Footer from "../../../../components/Footer"
import Processing from "../../../../assets/images/Processing.svg"
import Sending from "../../../../assets/images/Sending.svg"
import Success from "../../../../assets/images/Success.svg"
import Faild from "../../../../assets/images/Faild.svg"
import LodingGif from "../../../../assets/images/logo-loading.gif"
import NotFoundImg from "../../../../assets/images/404.svg"
import { useDispatch, useSelector } from "react-redux"
import { fetchData } from "../../../../store/tracking"

const HrLine = () => (
	<div>
		<hr />
	</div>
)

const LogHeader = ({ iconColor, iconSize }) => (
	<div className="DetailHeader">
		<div className="contacts">
			Emergency Contact
		</div>
		<div className="phone">
			<IconPhoneOutgoing color={iconColor} size={iconSize} />
			<p>Phone</p>
		</div>
		<div className="text">
			<IconMessage2 color={iconColor} size={iconSize} />
			<p>Text</p>
		</div>
		<div className="location">
			<IconMapPin color={iconColor} size={iconSize} />
			<p>Location</p>
		</div>
	</div>
)

const BorderLog = ({ contact_name, call_status, sms_status, map_status }) => (
	<div className="row">
		<div className="contacts">{contact_name}</div>
		<div className="phone">
			{call_status === "answered" || call_status === "completed" ? (
				<img src={Success} alt="Success" />
			) : call_status === "queued" || call_status === "accepted" || call_status === "started" || call_status === "ringing" ? (
				<img src={Sending} alt="Sending" />
			) : (
				<img src={Faild} alt="Faild" />
			)}
		</div>
		<div className="text">
			{sms_status === "delivered" ? (
				<img src={Success} alt="Success" />
			) : sms_status === "queued" || sms_status === "sent" ? (
				<img src={Sending} alt="Sending" />
			) : (
				<img src={Faild} alt="Faild" />
			)}
		</div>
		<div className="location">
			{map_status === 1 ? (
				<img src={Success} alt="Success" />
			) : (
				<img src={Processing} alt="Processing" className="processing-spin" />
			)}
		</div>
	</div>
)

const DividerLine = () => (
	<div>
		<div className="vertical-line"></div>
		<hr className="horizontal-line" />
	</div>
)

function Tracking() {
	const dispatch = useDispatch()
	const [data, setData] = useState(null)
	const [triggerTime, setTriggerTime] = useState("")
	const [baseClassName, setBaseClassName] = useState("container")
	const [isLoading, setIsLoading] = useState(true)
	const [isEmpty, setIsEmpty] = useState(true)
	const { tracking, phone } = useParams()
	const iconColor = "#7D7E8A"
	const iconSize = 24
	const apiUrl = `/tracking/getAlarmData/${tracking}/${phone}`
	const store = useSelector(state => state.tracking)

	useEffect(() => {
		if (store?.data && store?.data[0]) {
			setData(store?.data[0])
		} else {
			setIsEmpty(true)
		}
		setIsLoading(false)
	}, [store])

	useEffect(() => {
		if (data && Object.keys(data).length > 0) {
			setIsEmpty(false)

			if (data?.touch_border_logs.length > 0 && data?.inner_border_logs.length <= 0) {
				setBaseClassName("container singleContainer")
			} else if (data?.touch_border_logs.length <= 0 && data?.inner_border_logs.length > 0) {
				setBaseClassName("container singleContainer")
			}

			const date = new Date(data?.created_at)
			const hours = date.getHours() % 12 || 12
			const minutes = date.getMinutes()
			const amOrPm = date.getHours() >= 12 ? "PM" : "AM"
			const formattedTime = `${hours}:${minutes.toString().padStart(2, "0")} ${amOrPm}`
			setTriggerTime(formattedTime)
		}
	}, [data])

	useEffect(() => {
		setIsLoading(true)

		dispatch(
			fetchData({apiUrl})
		)

		const apiCallInterval = setInterval(() => {
			dispatch(
				fetchData({apiUrl})
			)
		}, 30000)

		return () => {
			clearInterval(apiCallInterval);
		}
	}, [dispatch, apiUrl])

	return (
		<div>
		{isLoading ? (
			<div className="App LoadingSection">
				<div>
					<img src={LodingGif} alt="Pico Act Tracking" />
					<p>Loading...</p>
				</div>
			</div>
		) : (
			<>
			{!isEmpty ? (
			<div className="App">
				<Header />

				<div className="MapSection">
					<MapContainer data={data} />
				</div>

				<div className="MessageSection">
					{data?.is_test && (
					<p className="error">GPS-coordinates could not be retrieved from the Car</p>
					)}
					<h2 className="main-title">{data?.device_name}</h2>
					<p className="help-message">{data?.alarm_text}</p>
				</div>

				<HrLine />

				<div className="DetailSection">
					<div className={baseClassName}>
						{data?.touch_border_logs.length > 0 && (
						<div className="column left">
							<div className="details">
								<div className="DetailTitle">
									{data?.is_test ? (
									<h2 className="title">Test Alarm</h2>
									) : (
									<h2 className="title">Touch Alarm</h2>
									)}
									<div className="time">
										{triggerTime}
									</div>
								</div>
								<p>Below is the current status of {data?.device_name} emergency contacts that has been notified.</p>
							</div>
							<div className="detail">
								<LogHeader
									iconColor={iconColor}
									iconSize={iconSize}
								/>
								<div className="DetailBody">
									{data?.touch_border_logs.map((log, index) => (
										<BorderLog
											key={index}
											contact_name={log.contact_name}
											sms_status={log.sms_status}
											call_status={log.call_status}
											map_status={log.map_status}
										/>
									))}
								</div>
							</div>
						</div>
						)}

						{data?.touch_border_logs.length > 0 && data?.inner_border_logs.length > 0 && (
							<DividerLine />
						)}

						{data?.inner_border_logs.length > 0 && (
						<div className="column right">
							<div className="details">
								<div className="DetailTitle">
									<h2 className="title">Inner Border</h2>
									<div className="time">
										{triggerTime}
									</div>
								</div>
								<p>Below is the current status of {data?.device_name} emergency contacts that has been notified.</p>
							</div>
							<div className="detail">
								<LogHeader
									iconColor={iconColor}
									iconSize={iconSize}
								/>
								<div className="DetailBody">
									{data?.inner_border_logs.map((log, index) => (
										<BorderLog
											key={index}
											contact_name={log.contact_name}
											sms_status={log.sms_status}
											call_status={log.call_status}
											map_status={log.map_status}
										/>
									))}
								</div>
							</div>
						</div>
						)}
					</div>
				</div>

				<HrLine />

				<Footer />
			</div>
			) : (
				<div className="App LoadingSection">
					<div>
						<h2>Page Not Found</h2>
						<p>Oops! 😖 The requested URL was not found on this server.</p>
						<img height="500" alt="error" src={NotFoundImg} />
					</div>
				</div>
			)}
			</>
		)}
		</div>
	)
}

export default Tracking
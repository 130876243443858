import React from "react"
import Logo from "../Logo"
import Timer from "../Timer"

function Header() {
	return (
		<header className="header">
			<Logo />
			<Timer />
		</header>
	)
}

export default Header
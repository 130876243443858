import React from "react"
import LogoImg from "../../assets/images/Logo.svg"

const Logo = () => {
	return (
		<div className="logo">
			<img src={LogoImg} alt="Pico Act" />
		</div>
	)
}

export default Logo
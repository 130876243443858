import React, { useState, useEffect } from "react"

const Timer = () => {
	const [remainingTime, setRemainingTime] = useState(3 * 60 * 60)

	useEffect(() => {
		const timer = setInterval(() => {
			if (remainingTime > 0) {
				setRemainingTime(remainingTime - 1)
			} else {
				clearInterval(timer)
			}
		}, 1000)

		return () => {
			clearInterval(timer)
		}
	}, [remainingTime])

	const formatTime = () => {
		const hours = Math.floor(remainingTime / 3600)
		let minutes = Math.floor((remainingTime % 3600) / 60)
		let seconds = remainingTime % 60

		minutes = (minutes < 10) ? "0" + minutes : minutes
		seconds = (seconds < 10) ? "0" + seconds : seconds

		return `${hours}:${minutes}:${seconds}`
	}

	return (
		<div className="right-content">
			<div>Live tracking will be active for</div>
			<div className="timer">{formatTime()}</div>
		</div>
	)
}

export default Timer
// ** Toolkit imports
import { configureStore } from "@reduxjs/toolkit"

// ** Reducers
import tracking from "./tracking"

export const store = configureStore({
	reducer: {
		tracking
	},
	middleware: getDefaultMiddleware =>
	getDefaultMiddleware({
		serializableCheck: false
	})
})
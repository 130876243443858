import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import customAxios from "../../configs/axios"

// ** Fetch Tracking
export const fetchData = createAsyncThunk("appTracking/fetchData", async params => {
	const response = await customAxios.get(params.apiUrl)
	.then(response => response)
	.then(data => {
		return data
	})
	.catch(error => {
		console.error("Error fetching data:", error)
	})

	return response.data
})

export const appTrackingSlice = createSlice({
	name: "appTracking",
	initialState: {
		data: [],
		params: {}
	},
	reducers: {},
	extraReducers: builder => {
		builder.addCase(fetchData.fulfilled, (state, action) => {
			state.data = (action.payload.success && action.payload.data) ? action.payload.data : null
			state.params = action.meta.arg
		})
	}
})

export default appTrackingSlice.reducer

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../fonts/Figtree-Regular.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../fonts/Figtree-Regular.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../fonts/Figtree-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../fonts/Figtree-Regular.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
	font-family: "Figtree";
	src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("woff2"),
		 url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("woff"),
		 url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("truetype"),
		 url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("opentype");
	font-weight: normal;
	font-style: normal;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/fonts.css"],"names":[],"mappings":"AAAA;CACC,sBAAsB;CACtB;;;6DAGwD;CACxD,mBAAmB;CACnB,kBAAkB;AACnB","sourcesContent":["@font-face {\n\tfont-family: \"Figtree\";\n\tsrc: url(\"../fonts/Figtree-Regular.woff2\") format(\"woff2\"),\n\t\t url(\"../fonts/Figtree-Regular.woff\") format(\"woff\"),\n\t\t url(\"../fonts/Figtree-Regular.ttf\") format(\"truetype\"),\n\t\t url(\"../fonts/Figtree-Regular.otf\") format(\"opentype\");\n\tfont-weight: normal;\n\tfont-style: normal;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

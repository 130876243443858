import "./assets/css/App.css"
import React from "react"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import Home from "./pages"
import Tracking from "./pages/v1/alarm/[tracking]/[phone]"
import NotFound from "./pages/404"
import { store } from "./store"
import { Provider } from "react-redux"

function App() {
	return (
		<Provider store={store}>
			<Router>
				<Routes>
					<Route exact path="/" element={<Home />} />
					<Route exact path="/v1/alarm/:tracking/:phone" element={<Tracking />} />
					<Route path="*" element={<NotFound />} />
				</Routes>
			</Router>
		</Provider>
	)
}

export default App
import React from "react"

function Footer() {
	return (
		<footer className="footer">
			<p>This Service is provided by PicoAct©</p>
		</footer>
	)
}

export default Footer
import React from "react"
import NotFoundImg from "../assets/images/404.svg"

const Home = () => {
	return (
		<div className="not-found">
			<div>
				<h2>Welcome Pico Act!</h2>
				<p>Oops! 😖 The requested URL was not found on this server.</p>
				<img height="500" alt="error" src={NotFoundImg} />
			</div>
		</div>
	)
}

export default Home
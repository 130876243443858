import React, { useState, useEffect } from "react"
import { GoogleMap, LoadScript } from "@react-google-maps/api"
import markerImg from "../../assets/images/marker.svg"

const MapContainer = (data) => {
	const mapApiKey = "AIzaSyBPoKL6o69cqp7Zyn2qH9kkOa93xtqO1zw"
	const mapContainerStyle = { width: "100%", height: "500px" }
	const zoomSize = 17
	const [center, setCenter] = useState({ lat: 40.778653, lng: -73.967904 })
	const [fixedMarkerPosition, setFixedMarkerPosition] = useState({ lat: 40.778653, lng: -73.967904 })
	const customMapStyles = [
		/*{
			featureType: "water",
			elementType: "geometry",
			stylers: [
				{
					color: "#2196F3"
				},
				{
					lightness: 17
				}
			]
		},
		{
			featureType: "landscape",
			elementType: "geometry",
			stylers: [
				{
					color: "#2196F3"
				},
				{
					lightness: 20
				}
			]
		},
		{
			featureType: "road.highway",
			elementType: "geometry.fill",
			stylers: [
				{
					color: "#FFFFFF"
				},
				{
					lightness: 17
				}
			]
		},
		{
			featureType: "road.highway",
			elementType: "geometry.stroke",
			stylers: [
				{
					color: "#FFFFFF"
				},
				{
					lightness: 29
				},
				{
					weight: 0.2
				}
			]
		},
		{
			featureType: "road.arterial",
			elementType: "geometry",
			stylers: [
				{
					color: "#FFFFFF"
				},
				{
					lightness: 18
				}
			]
		},
		{
			featureType: "road.local",
			elementType: "geometry",
			stylers: [
				{
					color: "#FFFFFF"
				},
				{
					lightness: 16
				}
			]
		},
		{
			featureType: "poi",
			elementType: "geometry",
			stylers: [
				{
					color: "#F5F5F5"
				},
				{
					lightness: 21
				}
			]
		},
		{
			featureType: "poi.park",
			elementType: "geometry",
			stylers: [
				{
					color: "#DEDEDE"
				},
				{
					lightness: 21
				}
			]
		},
		{
			elementType: "labels.text.stroke",
			stylers: [
				{
					"visibility": "on"
				},
				{
					color: "#FFFFFF"
				},
				{
					lightness: 16
				}
			]
		},
		{
			elementType: "labels.text.fill",
			stylers: [
				{
					"saturation": 36
				},
				{
					color: "#333333"
				},
				{
					lightness: 40
				}
			]
		},*/
		{
			elementType: "labels.icon",
			stylers: [
				{
					"visibility": "off"
				}
			]
		},
		/*{
			featureType: "transit",
			elementType: "geometry",
			stylers: [
				{
					color: "#F2F2F2"
				},
				{
					lightness: 19
				}
			]
		},
		{
			featureType: "administrative",
			elementType: "geometry.fill",
			stylers: [
				{
					color: "#FEFEFE"
				},
				{
					lightness: 20
				}
			]
		},
		{
			featureType: "administrative",
			elementType: "geometry.stroke",
			stylers: [
				{
					color: "#FEFEFE"
				},
				{
					lightness: 17
				},
				{
					weight: 1.2
				}
			]
		}*/
	]
	const customMapOptions = {
		disableDefaultUI: false,
		zoomControl: false,
		streetViewControl: false,
		scaleControl: false,
		fullscreenControl: false,
		mapTypeControl: false,
	}

	const handleMapLoad = (map) => {
		const latlng = new window.google.maps.LatLng(fixedMarkerPosition)

		// if (!data?.data?.is_test) {
			new window.google.maps.Marker({
				position: latlng,
				map: map,
				icon: markerImg,
				/*style: {
					width: "50px",
					height: "50px",
					position: "relative",
					top: "-50px",
					left: "-25px",
				}*/
			})
		// }

		/*const latlng = new window.google.maps.LatLng(fixedMarkerPosition)
		const geocoder = new window.google.maps.Geocoder()
		geocoder.geocode({ "latLng": latlng }, (results, status) => {
			if (status == window.google.maps.GeocoderStatus.OK) {
				console.log(results)
				const address = (results[0].formatted_address)
				// $("#address").html(address)
			}
		})*/
	}
	const handleCenterButtonClick = () => {
		setCenter({ lat: parseFloat(data?.data?.lat), lng: parseFloat(data?.data?.lng) });
	}

	useEffect(() => {
		setCenter({
			lat: parseFloat(data?.data?.lat),
			lng: parseFloat(data?.data?.lng)
		})

		setFixedMarkerPosition({
			lat: parseFloat(data?.data?.lat),
			lng: parseFloat(data?.data?.lng)
		})
	}, [data])

	return (
		<LoadScript googleMapsApiKey={mapApiKey}>
			<GoogleMap
				mapContainerStyle={mapContainerStyle}
				center={center}
				zoom={zoomSize}
				options={{
					styles: customMapStyles,
					...customMapOptions
				}}
				onLoad={handleMapLoad}
			/>
			<button
				onClick={handleCenterButtonClick}
				style={{display: "none", position: "absolute", cursor: "pointer", top: "8px", right: "25px"}}
			>
				Center
			</button>
		</LoadScript>
	)
}

export default MapContainer